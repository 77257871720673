.mx-1 {
    margin-left: 8px;
    margin-right: 8px;
}
.mx-2 {
    margin-left: 16px;
    margin-right: 16px;
}
.my-1 {
    margin-top: 8px;
    margin-bottom: 8px;
}
.my-2 {
    margin-top: 16px;
    margin-bottom: 16px;
}

.flex-row {
    display: flex;
}
.flex-col {
    display: flex;
    flex-direction: column;
}
.align-center {
    align-items: center;
}
.align-start {
    align-items: flex-start;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}


.absolute-fill {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
}






p{
    margin: 0px;
}



* {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}

*::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

svg > * {
    cursor: pointer;
}

#Digital-Therapeutics > *,
#Gene-Therapy-and-Gen > *,
#Microbiome > *,
#RNA > *,
#Cell-Therapy > *
{
    pointer-events: none;
}

#Digital-Therapeutics,
#Gene-Therapy-and-Gen,
#Microbiome,
#RNA,
#Cell-Therapy {
 pointer-events: bounding-box;   
}

#Gastrointestinal,
#Respiratory_disease,
#CNS,
#Oncology,
#Cardiovascular_and_metabolic_disease,
#Infectious_Diseases,
#Other_Diseases,
#Hematology,
#Autoimmune_allergic_disease,
#Ophthalmology,
#Dermatology,
#Muscle
{
    pointer-events: bounding-box;
}

#Gastrointestinal > *,
#Respiratory_disease > *,
#CNS > *,
#Oncology > *,
#Cardiovascular_and_metabolic_disease > *,
#Infectious_Diseases > *,
#Other_Diseases > *,
#Hematology > *,
#Autoimmune_allergic_disease > *,
#Ophthalmology > *,
#Dermatology > *,
#Muscle > *{
    pointer-events: none;
}